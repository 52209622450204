.contact-form {
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
    width: 80vw;
  }
  
  .contact-form label {
    margin-top: 10px;
    font-weight: 400;
    font-family: 'Khula', sans-serif;  
    color:#2f3035;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    margin-top: 5px;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
    color:#2f3035;
  }
  
  .contact-form button {
    background-color: white;
    color: #a7c5e7;
    border: 1px solid #a7c5e7; 
    border-radius: 10px; 
    font-weight: 600; 
    cursor: pointer; 
    margin-top: 15px;
    font-family: 'Khula', sans-serif; 
  }
  
  .contact-form button:hover {
    background-color: #274b69;
  }
  

