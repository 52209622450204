.footer {
    bottom: 0;
    width: 100%;
    margin-top: 3rem;
    /* margin-left: 15%; */
    padding: 1rem 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black; 
    font-size: 0.7em;
    font-family: 'Khula', sans-serif;    
  }

span {
    margin-left: 6rem;
  }
  
.socials {
  margin-right: 5rem;
}
  .social {
    height: 3vh;
    padding: .5rem;
  
  }

  /* Responsive styles for tablets */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 0.8em;
    padding: 1rem;
  }

  span {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .socials {
    margin: 0;
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
  .footer {
    font-size: 0.6em;
    padding: 0.5rem;
  }

  span {
    margin: 0;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .social {
    height: 4vh;
    padding: 0.3rem;
  }
}
