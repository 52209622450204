
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  width: 100%;
  background-color: #a7c5e7; 
  color: white;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between brand and links */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
    .logo {
      font-family: 'Italiana', serif; /* Add the Italiana font */
      font-size: 80px;
      color: white;
      margin-left: 5rem;
    }

    .nav-links {
      list-style: none;
      display: flex;
      gap: 40px;
      font-size: 20px;
      margin-right: 10rem;
    }

    .link {
      text-transform: uppercase; 
      text-decoration: none;     
      font-family: 'Khula', sans-serif;      
      color: white;     
    }

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 12rem;
}

.home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

    .sue-image {
    max-height: 70vh;
    padding-right: 3rem;
   
    }

    .intro {
      color: #1a1b1f;
      max-width: 40vw;
      font-family: 'Khula', sans-serif;    
      padding: 0;
    }

.books-container{
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.book-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Khula', sans-serif;    
  font-size: 1rem;
  margin-top: 6rem;
  padding: 3rem;
  width: 20vw
}
.book-image {
  width: 20vw;
  margin-bottom: 1rem;
}

.blurb {
line-height: 5px;
}
  
    .icon {
max-height: 5vh;
margin: 10px;
    }

    button {
      background-color: white;  
      color: #a7c5e7;
      border: 1px solid #a7c5e7;
      border-radius: 10px; 
      padding: 7px 10px; 
      font-size: 14px; 
      font-weight: 600; 
      cursor: pointer; 
      margin: 4px;
    }


    button:hover {
        background-color: #274b69;   
    }

    .book-date {
      text-transform: uppercase; 
    }
    .book-title {
      font-weight: bold; 
      font-size: 1.3rem;
      color:#2f3035;
      margin-top: -10px;
      margin-bottom: -10px;
    }

    @media (min-width: 800px) and (max-width: 1200px) {
      .logo {
        font-size: 50px;
        margin-left: 2rem;
      }
    
      .nav-links {
        gap: 20px;
        margin-right: 2rem;
        font-size: 16px;
      }
    
      .intro {
        max-width: 60vw;
        font-size: 1rem;
      }

      .book-container {
        width: 80vw;
      }
    
      .book-image {
        width: 30vw;
      }
.books-container {
  flex-direction: column;
}
      .home-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
      }

      .sue-image {
        max-height: 50vh;
        margin-left: 2rem;
        }

     .intro {
          max-width: 90vw;
        }

        .title {
          margin-left: 2rem;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
      .logo {
        font-size: 50px;
        margin-left: 2rem;
      }
    
      .nav-links {
        gap: 20px;
        margin-right: 2rem;
        font-size: 16px;
      }
    
      .intro {
        max-width: 60vw;
        font-size: 1rem;
      }
    
      .book-image {
        width: 40vw;
      }

      .home-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
      }

      .sue-image {
        max-height: 50vh;
        margin-left: 2rem;
        }

     .intro {
          max-width: 90vw;
        }

        .title {
          margin-left: 2rem;
        }
    }
 
    /* Responsive styles for mobile devices */
    @media (max-width: 480px) {
      .navbar {
        flex-direction: row;
        align-items: center;
        padding: 10px;
      }
    
      .logo {
        font-size: 40px;
        margin: 0;
      }
    
      .nav-links {
        flex-direction: column;
        gap: 10px;
        margin-right: 2rem;
        font-size: 14px;
      }
    
      .home-container {
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
      }
    
      .sue-image {
        max-width: 80vw;
        padding: 0;
      }
    
      .intro {
        max-width: 90vw;
        font-size: 0.9rem;
      }
    
      .book-image {
        width: 60vw;
      }
      .books-container{
        flex-direction: column;
      
      }
      
      .book-container {
        width: 60vw;
      }
      .button-container {
        width: 80vw;
      }
      
    }