body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Italiana', 'Khula'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
}

.a {
  text-decoration: none;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Italiana', serif; /* Add the Italiana font */
  font-size: 50px;
  color:#2f3035;
  /* padding-left: 7rem; */
}
